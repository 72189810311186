import PropTypes from 'prop-types';
import Image from 'next/image';
import React from 'react';
import Link from 'next/link';

export default function BlogCard({ item }) {
  return (
    <div
      data-aos="fade-up"
      className="flex flex-col gap-5 rounded-lg text-brand-main"
      key={item.id}
    >
      <Link
        href={`/blog/${item.slug}`}
        className="relative w-full bg-brand-main  h-[200px] sm:h-[300px] rounded-lg ring-2  ring-brand-brown"
      >
        <Image src={item.image} fill className="object-cover rounded-lg" alt={item.name} />
        <Link
          className="absolute bottom-0 right-0 px-3 pt-1 m-2 pb-[6px] text-sm font-semibold bg-white border-2 rounded-full md:text-sm max-w-prose text-brand-brown border-brand-brown"
          href={`/blog/${item.slug}`}
        >
          Aprender mas
        </Link>
      </Link>
      <h2 className="-mt-2 text-base md:text-lg payfair text-brand-secondary">{item.name}</h2>
      {item.metaDescription && (
        <p className="text-xs max-w-prose text-zinc-800 max-h-[150px] overflow-hidden">
          {item.metaDescription}
        </p>
      )}
      <div className="flex justify-between text-xs font-semibold md:text-sm text-brand-main">
        {item.author && <p className="-my-2 max-w-[150px] truncate">{item.author}</p>}
        {item.date && <p className="-my-2">{item.date}</p>}
      </div>
    </div>
  );
}

BlogCard.propTypes = {
  item: PropTypes.shape({
    author: PropTypes.any,
    date: PropTypes.any,
    id: PropTypes.any,
    image: PropTypes.any,
    metaDescription: PropTypes.any,
    name: PropTypes.any,
    slug: PropTypes.any,
  }),
};
