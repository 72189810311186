import PropTypes from 'prop-types';
import React from 'react';
import BlogCard from '../common/BlogCard';

export default function Blog({ data }) {
  if (data && data.length === 0) {
    return null;
  }
  return (
    <section id="blog" className="relative z-10 px-5 py-10 overflow-hidden bg-white md:py-20">
      <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-7xl text-brand-main">
        <div
          data-aos="zoom-in"
          data-aos-delay="200"
          className="flex flex-col items-center justify-center max-w-4xl mx-auto text-center"
        >
          <p className="text-sm md:text-base max-w-prose ">Recursos Gratuitos</p>
          <h2 className="mb-4 text-2xl md:mb-8 md:text-4xl payfair text-brand-secondary">
            Explora nuestras últimas publicaciones sobre estrategias de desarrollo personal y
            profesional, y obtén valiosas perspectivas que impulsarán tu crecimiento personal y tu
            avance en la carrera.
          </h2>
        </div>
        <div className="grid grid-cols-2 mt-10 md:grid-cols-3 gap-y-10 gap-x-5">
          {data?.map((item) => (
            <BlogCard item={item} key={item.id} />
          ))}
        </div>
      </div>
    </section>
  );
}

Blog.propTypes = {
  data: PropTypes.array,
};
